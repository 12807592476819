import React, { useState, useEffect } from 'react';
import Confetti from 'react-confetti'

interface ProgressProps {
    onComplete?: () => void;
}

const Rebour: React.FC<ProgressProps> = ({ onComplete }) => {
  const [count, setCount] = useState<number>(5);

  useEffect(() => {
    if (count > 0) {
      const timer = setInterval(() => {
        setCount(prevCount => prevCount - 1);
      }, 1000);
      
      return () => clearInterval(timer); // Nettoyage de l'intervalle
    }
  }, [count]);

  useEffect(() => {
    if (count === 0 && onComplete) {
      onComplete(); // Appeler le callback une fois la progression terminée
    }
  }, [count, onComplete]);

  return (
    <div>
        <div className={"text-9xl font-bold " + (count > 0 ?  "bg-gradient-to-r from-blue-200 to-pink-200 shadow-lg shadow-gray-200/50 text-gray-50 font-medium hover:bg-gradient-to-br hover:shadow-gray-200 rounded-full text-9xl p-16 text-center me-2 mb-2" : "text-blue-300 font-bold")}>
        {count > 0 ? count : <div><span className="text-4xl">C'est un </span> GARÇON <span className="text-4xl">!</span></div> }
    </div>
        {count < 1 &&
        <Confetti
            width={window.innerWidth}
            height={window.innerHeight}
            numberOfPieces={2000}
            colors={["#B2CAFF", "#B5DEFA", "#ABD8DF"]}
            initialVelocityX={100}
        />
        }
    </div>
  );
};

export default Rebour;